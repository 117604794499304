import MacIcon from "../assets/icons/Apple.png";
import WindowsIcon from "../assets/icons/Windows.png";
import AndroidIcon from "../assets/icons/Android.png";
import IPhoneIcon from "../assets/icons/IOS.png";
import ChromeIcon from "../assets/icons/Chrome.png";
import FireFoxIcon from "../assets/icons/Firefox.png";
import LinuxIcon from "../assets/icons/Linux.png";
import GoogleIcon from "../assets/icons/google_store.png";
import AppIcon from "../assets/icons/app_store.png";
import SmileSvg from "../assets/svg/smile.svg";
import FingerSvg from "../assets/svg/finger.svg";
import CheckSvg from "../assets/svg/check.svg";
import FacebookSvg from "../assets/svg/facebook.svg";
import TwitterSvg from "../assets/svg/twitter.svg";
import InstagramSvg from "../assets/svg/instagram.svg";
import DiscordSvg from "../assets/svg/discord.svg";
import LinkedinSvg from "../assets/svg/linkedin.svg";

export const photo_data = [
  "/assets/image/BG-2.png",
  "/assets/image/BrighterVPN_Illustration-1.png",
  "/assets/image/BrighterVPN_Illustration-2.png",
  "/assets/image/BrighterVPN_Illustration-3.png",
  "/assets/image/BrighterVPN_Illustration-4.png",
  "/assets/image/BrighterVPN-Trust.png",
  "/assets/image/vpn-server.png",
  "/assets/image/logo.png",
  "/assets/image/pure-vpn-logo.png",
  "/assets/image/logo-blue.png",
  "/assets/image/effect.png",
];

export const free_brighter_vpn_data = [
  "6,000+ High-Speed VPN Servers for lightning-fast, secure connections",
  "80+ Locations Across 65+ Countries to access content worldwide",
  "Easy-to-use VPN App compatible with all your devices",
  "Crypto-friendly Privacy for enhanced anonymity and security",
];

export const header_itmes = [
  {
    label: "What is VPN",
    link: "/what-is-vpn",
  },
  {
    label: "Apps",
    link: "/download",
  },
  {
    label: "Pricing",
    link: "/pricing",
  },
  {
    label: "Servers",
    link: "/servers",
  },
];

export const systems = [
  {
    icon: MacIcon,
    label: "Mac",
    link: "/macos-vpn",
  },
  {
    icon: WindowsIcon,
    label: "Windows",
    link: "/windows-vpn",
  },
  {
    icon: AndroidIcon,
    label: "Android",
    link: "/android-vpn",
  },
  {
    icon: IPhoneIcon,
    label: "iPhone",
    link: "/ios-vpn",
  },
  {
    icon: IPhoneIcon,
    label: "iPad",
    link: "/chrome-vpn",
  },
  {
    icon: ChromeIcon,
    label: "Chrome",
    link: "/chrome-vpn",
  },
  // {
  //   icon: FireFoxIcon,
  //   label: "FireFox",
  //   link: "/",
  // },
  // {
  //   icon: LinuxIcon,
  //   label: "Linux",
  //   link: "/",
  // },
];

export const users_section_data = [
  {
    status: "Invited",
    content: "The support team was fantastic! They listened to my concerns and provided solutions quickly. I couldn't be happier with the service!",
    day_before: 1,
  },
  {
    status: "Invited",
    content: "I had a wonderful experience! The staff was friendly and went above and beyond to help me with my issues.",
    day_before: 3,
  },
  {
    status: "Invited",
    content: "Thanks to the team for their prompt assistance! They resolved my issue in no time and made the process so easy.",
    day_before: 4,
  },
  {
    status: "Invited",
    content: "I appreciate the detailed attention given to my case. The team was knowledgeable and very supportive throughout!",
    day_before: 2,
  },
  {
    status: "Invited",
    content: "Exceptional service! Every question I had was answered thoroughly, and I felt supported every step of the way.",
    day_before: 5,
  },
];

export const servers_data = [
  {
    count: 65,
    label: "Countries",
  },
  {
    count: 6000,
    label: "VPN servers",
  },
  {
    count: 80,
    label: "Locations",
  },
];

export const stack_data = [
  {
    icon: SmileSvg,
    label: "3 Million+ Satisfied Users",
  },
  {
    icon: FingerSvg,
    label: "One Click Software",
  },
  {
    icon: CheckSvg,
    label: "No Log Policy",
  },
];

export const footer_apps_links = {
  title: "BrighterVPN Apps",
  content: [
    {
      label: "Windows VPN",
      link: "/windows-vpn",
    },
    {
      label: "Mac VPN",
      link: "/macos-vpn",
    },
    // {
    //   label: "Linux VPN",
    //   link: "",
    // },
    {
      label: "iPhone VPN",
      link: "/ios-vpn",
    },
    // {
    //   label: "Huawei VPN",
    //   link: "",
    // },
    {
      label: "Android VPN",
      link: "/android-vpn",
    },
    {
      label: "VPN Chrome Extension",
      link: "/chrome-vpn",
    },
    // {
    //   label: "VPN FireFox Extension",
    //   link: "",
    // },
    // {
    //   label: "VPN Edge Extension",
    //   link: "",
    // },
    // {
    //   label: "Android TV VPN",
    //   link: "",
    // },
    // {
    //   label: "Firestick TV VPN",
    //   link: "",
    // },
    // {
    //   label: "Apple TV VPN",
    //   link: "",
    // },
  ],
};

export const footer_setup_links = {
  title: "VPN Setup",
  content: [
    {
      label: "Routers VPN",
      link: "",
    },
    {
      label: "DDWRT Applet",
      link: "",
    },
  ],
};

export const footer_brightervpn_links = {
  title: "BrighterVPN",
  content: [
    {
      label: "What is a VPN?",
      link: "",
    },
    {
      label: "Benefits",
      link: "",
    },
    {
      label: "Trust Center",
      link: "",
    },
    {
      label: "Blog",
      link: "",
    },
  ],
};

export const footer_addons_links = {
  title: "VPN Add-ons",
  content: [
    {
      label: "Dedicated IP VPN",
      link: "",
    },
    {
      label: "Port Forwarding",
      link: "",
    },
  ],
};

export const footer_tools_links = {
  title: "Tools",
  content: [
    {
      label: "What is My IP",
      link: "",
    },
    {
      label: "DNS LeaK Test",
      link: "",
    },
    {
      label: "IPv6 Leak Test",
      link: "",
    },
    {
      label: "WebRTC Leak Test",
      link: "",
    },
  ],
};

export const footer_discover_links = {
  title: "Discover",
  content: [
    {
      label: "BrighterKeep",
      link: "",
    },
    {
      label: "BrighterEncrypt",
      link: "",
    },
    {
      label: "BrighterPrivacy",
      link: "",
    },
    {
      label: "Brightversity",
      link: "",
    },
  ],
};

export const footer_about_links = {
  title: "About BrighterVPN",
  content: [
    {
      label: "Pricing",
      link: "",
    },
    {
      label: "Features",
      link: "",
    },
    {
      label: "About Us",
      link: "",
    },
    {
      label: "BrighterVPN Reviews",
      link: "",
    },
  ],
};

export const footer_information_links = {
  title: "Information",
  content: [
    {
      label: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      label: "Refund Policy",
      link: "",
    },
    {
      label: "Terms of Service",
      link: "",
    },
  ],
};

export const footer_help_links = {
  title: "Help",
  content: [
    {
      label: "Support Center",
      link: "https://doc.brightervpn.com/",
    },
    {
      label: "VPN Setup Guides",
      link: "",
    },
    {
      label: "Contact Us",
      link: "mailto:hello@solarenterprises.com",
    },
  ],
};

export const footer_programs_links = {
  title: "Programs",
  content: [
    {
      label: "VPN Affiliate Program",
      link: "",
    },
    {
      label: "Student Discount",
      link: "",
    },
    {
      label: "Family Plan",
      link: "",
    },
  ],
};

export const footer_business_links = {
  title: "VPN For Business",
  content: [
    {
      label: "VPN for Teams",
      link: "",
    },
    {
      label: "Developers (API)",
      link: "",
    },
    {
      label: "White Label VPN",
      link: "",
    },
    {
      label: "VPN Resellers Program",
      link: "",
    },
  ],
};

export const footer_follow_links = {
  title: "Follow Us",
  content: [
    {
      icon: FacebookSvg,
      label: "Facebook",
      link: "https://www.facebook.com/share/vwFmqBQAmynUD1Nb/?mibextid=LQQJ4d",
    },
    {
      icon: TwitterSvg,
      label: "Twitter",
      link: "https://x.com/brightervpn?s=21",
    },
    {
      icon: InstagramSvg,
      label: "Instagram",
      link: "https://www.instagram.com/brightervpn/",
    },
    // {
    //   icon: DiscordSvg,
    //   label: "Discord",
    //   link: "",
    // },
    // {
    //   icon: LinkedinSvg,
    //   label: "LinkedIn",
    //   link: "",
    // },
  ],
};

export const footer_download_links = {
  title: "Download",
  content: [
    {
      icon: GoogleIcon,
      label: "google store",
      link: "https://play.google.com/store/apps?hl=en_US",
    },
    {
      icon: AppIcon,
      label: "app store",
      link: "https://apps.apple.com/us/app/apple-store/id375380948",
    },
  ],
};
